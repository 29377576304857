const actions = {

    // SENDING FUNCTIONS

    async GET_MOBILE_FEED({dispatch,rootState} ) {

        return await dispatch('ajax/SEND_NEW',
            {
                route:`feed/mobile`,
                method:'GET',
                business : rootState.business.selectedBusiness.alias,
            },{ root : true });
    },

    async ADD_MOBILE_FEED_POST({dispatch,rootState} , body ) {

        return await dispatch('ajax/SEND_NEW',
            {
                route:`feed/mobile/post`,
                method:'POST',
                data : body,
                business : rootState.business.selectedBusiness.alias,
            },{ root : true });
    },


    // SUPERADMIN


    async GET_BUSINESS_WITH_APPS({dispatch}  ) {
        return await dispatch('ajax/SEND_NEW',
            {
                route:`mobile/active_businesses/select`,
                method:'POST',
            },{ root : true });
    }



}

export default {
    namespaced:true,
    actions
}